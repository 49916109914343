<template>
    <section>
        <cargando v-if="cargando" />
        <div class="p-2 f-15 h-90 mx-2">
            <div class="row mx-0 align-items-center">
                <el-tooltip effect="light" content="Atras" placement="bottom">
                    <i class="icon-back f-20 text-general cr-pointer" @click="irAtras" />
                </el-tooltip>
                <div class="col-auto px-2 f-17 text-general">
                    {{ categoria.nombre }}
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4 ml-4">
                <div class="col-auto px-2 text-general2">
                    <p class="f-14">{{ categoria.descripcion }}</p>
                </div>
            </div>
            <div class="row mx-0 my-3">
                <div class="col-auto mb-3">
                    <div class="row mx-0">
                        <div class="card-categoria border br-12 position-relative" style="width:160px;">
                            <img :src="categoria.banner ? categoria.banner_firmado : '/img/no-imagen/list.svg'" class="obj-cover br-t-10 cr-pointer" height="180" width="100%" />
                        </div>
                    </div>
                    <div class="row mx-5 mt-2 align-items-center">
                        <div class="col-auto px-0">
                            <div class="d-middle-center btn-edit border-gris cr-pointer br-10 text-black text-center" style="width:34px;height:34px;" @click="editarCategoria">
                                <el-tooltip effect="light" content="Editar" placement="bottom">
                                    <i class="icon-pencil-outline f-20" />
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="col-auto px-0 mx-1">
                            <div class="d-middle-center cr-pointer border-gris br-10 text-black" style="width:34px;height:34px;" @click="eliminarCategoria">
                                <el-tooltip effect="light" content="Eliminar" placement="bottom">
                                    <i class="icon-delete-outline f-20 hover-inverse" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <ValidationObserver ref="validacion">
                        <div class="row mx-0">
                            <div class="col-8">
                                <p class="text-general f-13 mx-2">Nombre de la subcategoría</p>
                                <ValidationProvider v-slot="{errors}" rules="required|max:30" name="Nombre de la subcategoría" class="d-flex flex-column">
                                    <el-input v-model="nombre" class="w-100" placeholder="Nombre" size="small" maxlength="30" show-word-limit />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto px-0 pt-1">
                                <el-tooltip class="item" effect="light" content="Crear subcategoría" placement="bottom">
                                    <div class="btn-red cr-pointer br-8 mt-3" @click="postSubCategoria">
                                        <i class="icon-plus f-16 text-white" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </ValidationObserver>
                    <div class="row mx-0 mt-4">
                        <div class="col">
                            <p class="text-general">Lista de subcategorías</p>
                            <template v-if="subCategorias.length > 0">
                                <ValidationObserver ref="validacionUpdate" v-slot="{ valid }">
                                    <div v-for="(data, idx) in subCategorias" :key="idx" class="col-auto px-1">
                                        <div class="row mx-0 my-2 align-items-center">
                                            <div class="col-8 px-2">
                                                <ValidationProvider v-slot="{errors}" :vid="'vp'+idx" rules="required|max:30" name="Nombre de la subcategoría" class="d-flex flex-column">
                                                    <el-input v-model="data.nombre" :disabled="!data.editar" class="pr-input-45" size="small" maxlength="30" show-word-limit />
                                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-auto px-0">
                                                <el-tooltip v-if="!data.editar" class="item" effect="light" content="Editar" placement="bottom">
                                                    <div class="d-middle-center btn-edit border-gris cr-pointer br-10 text-black" style="width:34px;height:34px;">
                                                        <i class="icon-pencil-outline f-20" @click="data.editar=!data.editar" />
                                                    </div>
                                                </el-tooltip>
                                                <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                                    <div class="bg-fondo border-gris cr-pointer d-middle-center br-10" style="width:34px;height:34px;">
                                                        <i class="icon-ok-circled-outline text-green f-20" @click="data.editar=!data.editar, updateSubCategoria(valid, data)" />
                                                    </div>
                                                </el-tooltip>
                                            </div>
                                            <div class="col-auto px-0 mx-1">
                                                <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                                    <div class="d-middle-center cr-pointer border-gris br-10 text-black" :class="!data.editar ? '' : 'invisible'" style="width:34px;height:34px;" @click="confirmarEliminarSubCategoria(data.id)">
                                                        <i class="icon-delete-outline f-20 hover-inverse" />
                                                    </div>
                                                </el-tooltip>
                                            </div>
                                            <el-tooltip class="item col-auto" effect="light" placement="bottom">
                                                <div slot="content" class="text-center" style="max-width:135px;">
                                                    Productos en esta Subcategoría
                                                </div>
                                                <div class="br-10 text-white px-2 f-17 ml-2 bg-general3 col-auto">
                                                    <i class="icon-package-variant-closed f-18 mr-2" />
                                                    <span>{{ data.productos }}</span>
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </template>
                            <template v-else>
                                <div class="col-auto mt-3">
                                    <p class="f-13">No hay subcategorías creadas</p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalEliminar" no-aceptar titulo="Eliminar subcategoría" icon="trash" adicional="Eliminar" @adicional="deleteSubCategoria">
            <div class="row mx-0">
                <div class="col text-center">
                    <p class="text-general">¿Desea eliminar la sub-categoría? <br /> Esta acción no puede ser revertida.</p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import Service from "~/services/configurar/productos";

export default {

    props: {
        categoria: {
            type: Object,
            default: () => {},
        }
    },

    data(){
        return {
            cargando: false,
            subCategorias: [],
            nombre: '',
            idSubCategoria: null,
        }
    },

    mounted(){
        if(!_.isEmpty(this.categoria)){
            this.getSubCategorias();
        }
    },

    methods: {
        async getSubCategorias(){
            try {
                this.cargando = true;
                const {data} = await Service.getSubCategorias(this.categoria.id);
                this.subCategorias = data.subcategorias;
            } catch(e){
                this.error_catch(e);
            } finally {
                this.cargando = false;
            }
        },

        async postSubCategoria(){
            try {
                if(_.isEmpty(this.categoria))return;

                const valid = await this.$refs.validacion.validate();
                if(!valid)return;

                const payload = {
                    idCategoria: this.categoria.id,
                    nombre: this.nombre,
                };
                const {data} = await Service.postSubCategoria(payload);
                if(data.exito){
                    await this.clear();
                    this.notificacion('Mensaje', 'Se ha creado una Subcategoría', 'success');
                    this.subCategorias.push(data.nuevaCategoria);
                    this.$refs.validacion.reset();
                }
            } catch(e){
                this.error_catch(e);
            } finally {
                setTimeout(() => {
                    this.$refs.validacion.reset();
                },3000);
            }
        },

        async updateSubCategoria(errors, object){
            try {
                if(!errors) return object.editar = true;

                const payload = {
                    nombre: object.nombre,
                };
                const {data} = await Service.putSubCategoria(object.id, payload);
                if(data.exito){
                    this.notificacion('Mensaje', 'Se ha actualizado la subcategoría', 'success');
                }
            } catch(e){
                this.error_catch(e);
            }
        },

        confirmarEliminarSubCategoria(idSubCategoria){
            this.idSubCategoria = idSubCategoria;
            this.$refs.modalEliminar.toggle();
        },

        async deleteSubCategoria(){
            try {
                if(!this.idSubCategoria)return;

                const {data} = await Service.deleteSubCategoria(this.idSubCategoria);
                if(data.exito){
                    this.notificacion('Mensaje', 'Se ha eliminado la subcategoría correctamente', 'success');

                    await this.deleteElement();
                    await this.clear();
                    await this.closeModal();
                }
            } catch(e){
                this.error_catch(e);
            }
        },

        async deleteElement(){
            let index = this.subCategorias.findIndex(item => item.id === this.idSubCategoria);
            if(index !== -1){
                this.subCategorias.splice(index, 1);
            }
        },

        async clear(){
            this.nombre = '';
            this.idSubCategoria = null;
        },

        async closeModal(){
            this.$refs.modalEliminar.toggle();
        },

        editarCategoria(){
            this.$emit('emitsBotones', 'editar');
        },

        eliminarCategoria(){
            this.$emit('emitsBotones', 'eliminar');
        },

        irAtras(){
            this.clear();
            this.subCategorias = [];
            this.$emit('emitsBotones', 'mostrarCategorias');
        },
    },
}

</script>
